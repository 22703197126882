export function getDefaulLocalState({
  type = "catalog",
  rootId = "root",
  reactRootId = "react-root",
  mainEntity = {},
  locale = "",
  currencyCodeISO = "",
  viewer,
  pageEntity,
  fragment
}) {
    let metadata = mainEntity.metadata || {
        use: false,
        isVisible: false,
        titleIsVisible: false,
        grid: "",
        orderId: "",
        token: ""
    };

    return {
    renderInfo: {
      __typename: "RenderInfo",
      type,
      rootId,
      reactRootId,
      mainEntity: {
        ...mainEntity,
        blockId: rootId,
        __typename: "MainEntity",
        mode: viewer.mode,
        metadata: {
            orderId: metadata.orderId,
            token: metadata.token,
            use: metadata.use,
            isVisible: metadata.isVisible,
            titleIsVisible: metadata.titleIsVisible,
            grid: metadata.grid,
            __typename: "Metadata",
        },
      },
      locale,
      currencyCodeISO,
      pageEntity: {
        ...pageEntity,
        __typename: "PageEntity"
      },
      fragmentEntity: {
        ...fragment,
        __typename: "FragmentEntity"
      }
    }
  };
}
