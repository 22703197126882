import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { hydrate } from "emotion";
import { typeDefs, fragmentMatcher } from "./common";
import gql from "graphql-tag";
import SystemJS from "systemjs/dist/system-production";
import fetch from 'unfetch';
import { createHttpLink } from 'apollo-link-http';

// @todo hack проблема при инициализации модулей, с cdn по умолчанию SystemJS добавляет define,
// и после этого инициализация не происходит, обнуляем define
window.define = null;

const HOST_API = process.env.GRAPHQL_ENDPOINT_URL;
const STYLES_NAMESPACE = "__ids";

async function importComponent(name) {
    return SystemJS.import(name);
}

const IndexApp = async (cachePrefix, gqlCtxToken, srcLink, componentName) => {
    const cache = new InMemoryCache({ fragmentMatcher }).restore(
        window.__APOLLO_STATE__[cachePrefix] || {}
    );

    let clientState = {
        cache,
        typeDefs
    };

    const stateLink = withClientState({
        ...clientState,
        resolvers: {
            Mutation: {
                updateCartData: (_, { count }, { cache }) => {
                    const data = {
                        cartData: {
                            __typename: "CartData",
                            count: count
                        }
                    };
                    const query = gql`
            query getCartData {
              cartData @client {
                count
              }
            }
          `;
                    cache.writeQuery({ query, data });
                    return null;
                }
            }
        }
    });

    const AuthLink = (operation, forward) => {
        operation.setContext(context => ({
            ...context,
            headers: {
                ...context.headers,
                "X-GQL-CTX-TOKEN": gqlCtxToken
            }
        }));

        return forward(operation);
    };

    const httpLink = createHttpLink({ uri: HOST_API, fetch: fetch, credentials: "same-origin" });

    let apolloClientConfig = {
        link: ApolloLink.from([
            AuthLink,
            stateLink,
            httpLink
        ]),
        cache,
        connectToDevTools: false,
        defaultOptions: {
            watchQuery: {
                errorPolicy: "ignore"
            },
            query: {
                errorPolicy: "ignore"
            }
        }
    };

    try {
        const apolloClient = new ApolloClient(apolloClientConfig);

        const getRenderInfo = apolloClient.query({
            query: gql`
        query getRenderInfo {
          renderInfo @client {
            reactRootId
            type
            mainEntity {
              page
              blockId
              id
              mode
              metadata {
                use
                orderId
                token
                grid
                isVisible
                titleIsVisible
              }
            }
            locale
            currencyCodeISO
            pageEntity {
              id
            }
          }
        }
      `
        });

        const { data } = await getRenderInfo;
        const {
            renderInfo: {
                type,
                reactRootId,
                mainEntity,
                locale,
                currencyCodeISO,
                pageEntity
            }
        } = data;

        let Comp;
        if (srcLink) {
            const res = await importComponent(srcLink);
            console.log(res);
            Comp = res.default;
        }
        apolloClient.link = hydrate(window[`${STYLES_NAMESPACE}-${reactRootId}`]);

        ReactDOM.hydrate(
            <Comp
                {...{
                    apolloClient,
                    type,
                    mainEntity,
                    locale,
                    currencyCodeISO,
                    pageEntity
                }}
            />,
            document.getElementById(reactRootId)
        );
    } catch (e) {
        console.log(e);
    }
};

// Этa функция требуется для добавления IndexApp к любому объекту.
// Используется на редакторе 1.0 для вывода react блоков
let AddIndexApp = function(o) {
    o.IndexApp = IndexApp;
};

export {AddIndexApp};

